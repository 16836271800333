import React from 'react';
import { useChallengeData } from './ChallengeDataContext';
import { FCWithChildren } from '@wix/challenges-web-library';

export function withChallengeData<Props = any>(Component: FCWithChildren<any>) {
  return (props: Props) => {
    const programData = useChallengeData();
    return <Component {...props} {...programData} />;
  };
}
